/* You can add global styles to this file, and also import other style files */

@import 'phoenix.colors';
@import 'phoenix.fonts';

body {
    background-color: $color-bg-1;
    color: $color-text-1;
    font-family: $font-family-1;
    font-size: $font-size-1;
    margin: 0;
    background-image: url('assets/bg_main.png');
    background-repeat: repeat-x;
}

.content-main {
    margin-bottom: 20px;
    background-color: $color-bg-1;
    border: 1px solid transparent;
    border-radius: 0px;
    box-shadow: $color-shadow-1;

    border-color: $color-border-2;
}

.buttons-outside-content {
    margin-bottom: 20px;
}

.content-heading {
    background-color: $color-bg-2;
    color: $color-text-1;
    font-weight: bold;

    padding: 10px 15px;
    border-bottom: 1px solid transparent;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}

.content-body {
    padding: 0px;

    .container-fluid {
        padding: 0;
    }
}

button.burger {
    color: $color-text-3;
    background-color: $color-bg-1;
    border-color: $color-border-4;

    fa-icon {
        color: $color-text-5;
    }
}

h3.content-title {
    background-color: $color-bg-2;
    color: $color-text-1;
    font-size: $font-size-1;
    font-weight: bold;

    padding: 10px 15px;
    border-bottom: 1px solid $color-border-4;
}

.subtitle {
    h5 {
        margin-bottom: 0px;
    }
}

.content-padding.subtitle {
    padding-bottom: 0px;
}

.btn-phoenix {
    color: $color-bg-1;
    border-color: $color-phoenix-bluegreen;
    background-color: $color-phoenix-bluegreen;
    font-family: $font-family-1;
    font-size: $font-size-1;

    &:hover {
        color: $color-bg-1;
        background-color: $color-phoenix-2;
        border-color: $color-phoenix-2;
    }

    &:disabled {
        background-color: $color-phoenix-4;
        color: $color-bg-1;
        border-color: $color-phoenix-4;
        cursor: not-allowed;
    }
}

.btn.btn-phoenix.btn-primary:active,
.btn.btn-phoenix.btn-primary:focus {
    color: $color-bg-1;
    background-color: $color-phoenix-bluegreen;
    border-color: $color-phoenix-bluegreen;
}

.btn.btn-phoenix.btn-primary:active:focus,
.btn.btn-phoenix.btn-primary:focus {
    box-shadow: none;
}


.content-padding {
    padding: 15px;
}

.body-head {
    min-height: 70px;
}

.modal-footer.confirm {
    justify-content: space-between;
}

.modal-footer.one {
    justify-content: center;
}

.modal-lg {
    max-width: 500px;
}

.modal-header {
    justify-content: center;
    color: $color-phoenix-bluegreen;
    background-color: $color-bg-1;
}

.modal-body {
    text-align: center;

    .icon {
        margin-top: 20px;
        margin-bottom: 35px;
        fa-icon {
            color: $color-phoenix-bluegreen;
        }
    }
}

input::placeholder {
    color: $color-text-8 !important;
}

input::-webkit-input-placeholder {
    color: $color-text-8 !important;
}
     
input:-moz-placeholder { /* Firefox 18- */
    color: $color-text-8 !important;  
}
     
input::-moz-placeholder {  /* Firefox 19+ */
    color: $color-text-8 !important;  
}
     
input:-ms-input-placeholder {  
    color: $color-text-8 !important;  
}

textarea::placeholder {
    color: $color-text-8 !important;
}

textarea::-webkit-input-placeholder {
    color: $color-text-8 !important;
}
     
textarea:-moz-placeholder { /* Firefox 18- */
    color: $color-text-8 !important;  
}
     
textarea::-moz-placeholder {  /* Firefox 19+ */
    color: $color-text-8 !important;  
}
     
textarea:-ms-input-placeholder {  
    color: $color-text-8 !important;  
}

form {
    .alert.alert-danger {
        background-color: $color-bg-1;
        color: $color-text-7;
        border: none;
        padding-left: 0px;
    }
}

.star {
    color: $color-text-7;
}

.fa-button {
    padding: 0;
    border: none;
    cursor: pointer;
    background-color: $color-bg-1;
    color: $color-phoenix-bluegreen;

    &:hover {
        color: $color-phoenix-2;
    }
}