@font-face {
    font-family: Lato;
    src:
        // url('/assets/fonts/Lato/Lato-Black.ttf') format('truetype'),
        // url('/assets/fonts/Lato/Lato-BlackItalic.ttf') format('truetype'),
        // url('/assets/fonts/Lato/Lato-BoldItalic.ttf') format('truetype'),
        // url('/assets/fonts/Lato/Lato-HairlineItalic.ttf') format('truetype'),
        // url('/assets/fonts/Lato/Lato-Italic.ttf') format('truetype'),
        // url('/assets/fonts/Lato/Lato-Light.ttf') format('truetype'),
        // url('/assets/fonts/Lato/Lato-LightItalic.ttf') format('truetype'),
        // url('/assets/fonts/Lato/Lato-Italic.ttf') format('truetype'),
        url('/assets/fonts/Lato/Lato-Regular.ttf') format('truetype')
}

$font-family-1: Lato, serif;

$font-size-1: 14px;